var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-8 px-4 settings-content",staticStyle:{"max-width":"425px"}},[_c('h4',{staticClass:"base-form__title"},[_vm._v(" Collections (optional) ")]),_c('p',{staticClass:"settings-description gray-color my-4"},[(_vm.isPromoter)?[_vm._v(" Performance Rights Organization (PRO)."),_c('br'),_c('br'),_vm._v(" Are you as a Venue/Festival/Promoter paying any local collection society? Aslice can generate reports and in some cases work directly with your PRO to help make sure your money is being paid down the line correctly to all the producers who are being played in your venues/events. ")]:[_vm._v(" Being a member of a collection society (PRO) is not required to be part of Aslice, but is highly encouraged. If you’re not registered please check this "),_c('a',{staticClass:"link-span",attrs:{"target":"_blank","href":"/copyrightexplained"}},[_vm._v("LINK")]),_vm._v(" explaining the importance of PRO’s, publishing and how they affect you. ")]],2),_c('p',{staticClass:"settings-text"},[_vm._v(" Performance Rights Organization — ONLY ")]),_c('v-select',{staticStyle:{"margin-bottom":"15px","margin-top":"10px"},attrs:{"label":"name","placeholder":"Select","options":_vm.societies,"value":_vm.userCopy.societies[0]},on:{"input":_vm.selectSociety},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('img',_vm._b({attrs:{"src":require("@/assets/images/arrow-playlist-optionsOne.svg")}},'img',attributes,false))]}},{key:"option",fn:function(ref){
var name = ref.name;
var id = ref.id;
return [_c('div',{staticClass:"cell-item",attrs:{"id":("cell-item-" + id)}},[_vm._v(_vm._s(name))]),_c('b-tooltip',{attrs:{"target":("cell-item-" + id)}},[_vm._v(_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var id = ref.id;
return [_c('div',{staticClass:"cell-item",attrs:{"id":("cell-item-" + id)}},[_vm._v(_vm._s(name))]),_c('b-tooltip',{attrs:{"target":("cell-item-" + id)}},[_vm._v(_vm._s(name))])]}}])}),_c('div',{staticClass:"form-group",staticStyle:{"margin-top":"15px"}},[_c('label',{staticClass:"settings-text"},[(_vm.isPromoter)?[_vm._v(" Contact person at society selected above (if known) ")]:[_vm._v(" Publisher Email ")]],2),_c('input',{staticClass:"form-control pink-color",attrs:{"type":"text","id":"email"},domProps:{"value":_vm.userCopy.publisherEmail},on:{"input":function($event){return _vm.UPDATE_USER_COPY({ publisherEmail: $event.target.value || null })}}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }